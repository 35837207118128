// import axios from 'axios'
import { ref, Ref } from 'vue'

const tokenKey = 'carab-pos-token'

const token: Ref<string> = ref('')

const savedToken = localStorage.getItem(tokenKey)

if (savedToken) {
  token.value = savedToken
}

export async function login (username: string, password: string): Promise<boolean> {
  // Für Testzwecke: Prüfe auf hardcodierte Anmeldedaten
  if (username === 'info@carab.ch' && password === '123nbv785') {
    const dummyToken = 'test-jwt-token'
    token.value = dummyToken
    localStorage.setItem(tokenKey, token.value)
    return true
  }

  // Hier könnte man später die echte API-Anfrage implementieren
  // const response = await axios.post('/login', {
  //   username: username,
  //   password: password
  // })

  // if (response.data && response.data.jwt) {
  //   token.value = response.data.jwt
  //   localStorage.setItem(tokenKey, token.value)
  //   return true
  // }

  return false
}

export function useToken (): Ref<string> {
  return token
}

export function logout (): void {
  token.value = ''
  localStorage.removeItem(tokenKey)
}
/*
import axios from 'axios'
import { ref } from 'vue'

const tokenKey = 'carab-pos-token'

const token = ref('')

const savedToken = localStorage.getItem(tokenKey)

if (savedToken) {
  token.value = savedToken
}

export async function login (username: string, password: string) {
  const response = await axios.post('/login', {
    username: username,
    password: password
  })

  if (response.data && response.data.jwt) {
    token.value = response.data.jwt
    localStorage.setItem(tokenKey, token.value)

    return true
  } else {
    return false
  }
}

export function useToken () {
  return token
}

export function logout () {
  token.value = ''
  localStorage.removeItem(tokenKey)
}
*/
