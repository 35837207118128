<template>
  <div class="home">
    <h1>CARAB POS System</h1>

    <div class="teaser-container">
      <div>
        <a href="/pos">
          <span>Neue Bestellung</span>
        </a>
      </div>

      <div class="disabled">
        <a href="#">
          <span>Wareneingang erfassen</span>
        </a>
      </div>

      <div class="logout">
        <a @click="logout" href="#">
          <span>Logout</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { logout } from '@/utils/login'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {},
  setup () {
    function onLogout () {
      logout()
    }

    return {
      onLogout
    }
  }
})
</script>

<style lang="scss" scoped>
  .teaser-container {
    max-width: 700px;
    margin: 0 auto;
    display: flex;

    div {
      width: 33%;
      border: 1px solid #eee;
      background-color: #aaa;

      &.logout {
        background-color: rgb(253, 77, 77);
      }

      &.disabled {
        background-color: rgb(218, 218, 218);

        a {
          cursor: not-allowed;
        }
      }

      a {
        display: flex;
        height: 75px;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: black;
        font-weight: bold;
      }
    }
  }
</style>
